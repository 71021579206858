import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { CalendarIcon, BeakerIcon, SparklesIcon } from '@heroicons/react/24/outline';

const Header = () => (
  <header className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg">
    <nav className="container mx-auto px-6 py-4">
      <div className="flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold flex items-center">
          <img src="/favicon-32x32.png" alt="HealBytes Logo" className="w-8 h-8 mr-2" />
          HealBytes
        </Link>
        <div className="flex space-x-6">
          <Link to="/" className="hover:text-purple-200 transition-colors">Home</Link>
          <Link to="/about" className="hover:text-purple-200 transition-colors">About</Link>
        </div>
      </div>
    </nav>
  </header>
);

const Footer = () => (
  <footer className="bg-gray-800 text-white mt-12">
    <div className="container mx-auto px-6 py-4">
      <div className="flex justify-between items-center">
        <p>&copy; 2024 HealBytes. All rights reserved.</p>
        <p>Created by Mohamed Zahran</p>
      </div>
    </div>
  </footer>
);

const AppCard = ({ title, description, icon, link, comingSoon }) => (
  <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col items-center transform transition duration-500 hover:scale-105">
    <div className="bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full p-3 mb-4">
      {icon}
    </div>
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <p className="text-gray-600 text-center mb-4">{description}</p>
    {!comingSoon && (
      <a href={link} className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-6 py-2 rounded-full hover:from-purple-700 hover:to-indigo-700 transition-colors">
        Launch App
      </a>
    )}
    {comingSoon && (
      <span className="text-gray-500 italic">Coming Soon</span>
    )}
  </div>
);

const Home = () => (
  <div className="container mx-auto px-6 py-12">
    <h1 className="text-4xl font-bold text-center mb-4 text-gray-800">Welcome to HealBytes</h1>
    <p className="text-xl text-center mb-12 text-gray-600">Exploring innovative solutions across various domains</p>
    
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <AppCard
        title="Masjidi"
        description="Prayer Time App - Never miss a prayer with our accurate and user-friendly prayer time calculator."
        icon={<CalendarIcon className="h-8 w-8 text-white" />}
        link="https://healbytes.co/Masjidi/"
      />
      <AppCard
        title="HaemCount"
        description="Haematology Differential Count App - Streamline your blood cell counting process with our intuitive digital counter."
        icon={<BeakerIcon className="h-8 w-8 text-white" />}
        link="https://diffy.healbytes.co/"
      />
      <AppCard
        title="Future Innovations"
        description="We're constantly working on new ideas. Stay tuned for upcoming projects spanning various fields and interests."
        icon={<SparklesIcon className="h-8 w-8 text-white" />}
        comingSoon={true}
      />
    </div>
  </div>
);

const About = () => (
  <div className="container mx-auto px-6 py-12">
    <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">About HealBytes</h1>
    <div className="bg-white rounded-xl shadow-lg p-8 max-w-3xl mx-auto">
      <p className="text-xl mb-6 text-gray-600">
        HealBytes is a platform dedicated to exploring and developing innovative solutions across various domains, with a strong focus on health and wellness applications.
      </p>
      <p className="text-xl mb-6 text-gray-600">
        Our mission is to leverage technology to improve lives, streamline processes, and foster innovation in multiple fields.
      </p>
      <p className="text-xl text-gray-600">
        Founded by Mohamed Zahran, HealBytes continues to develop cutting-edge tools and applications to serve diverse needs and interests.
      </p>
    </div>
  </div>
);

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;